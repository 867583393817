import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CsrfTokenContext } from './csrfContext';
import axios from "axios";
import { fetchStocksDataRoute, updateProductStockRoute } from '../utils/APIRoutes';
import Swal from 'sweetalert2';
import '../assets/styles/stockManagement.css';
import { TagPicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'; 

const StockManagement = () => {
    const { csrfToken } = useContext(CsrfTokenContext);
    const [isStockFormVisible, setIsStockFormVisible] = useState(false);
    const [productCode, setProductCode] = useState(null);
    const [stockQuantities, setStockQuantities] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [products, setProducts] = useState([]);
    const isLoggedIn = window.localStorage.getItem("user");
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [enablePage, setEnablePage] = useState("none");
    const [stockPage, setStockPage] = useState(1);
    const itemsPerPage = 10;
    const [selectedSizes, setSelectedSizes] = useState([]);
    const [quantity30x30, setQuantity30x30] = useState(0);
    const [quantity40x40, setQuantity40x40] = useState(0);
    const [quantity60x60, setQuantity60x60] = useState(0);
    const STOCK_THRESHOLD = 30;

    useEffect(() => {
        //isLoggedIn ? navigate("/") : navigate("/login");
        if (isLoggedIn) {
            //navigate("/portal/stock-management");
            document.title = "JCJHomeBuilders Portal - Stock Management"
            setEnablePage("block");
        } else {
            setEnablePage("none");
            navigate("/portal/login");
            document.title = "JCJHomeBuilders Portal - Log In"
        }
    }, [isLoggedIn, navigate]);

    useEffect(() => {
        const fetchData = async () => {
            // Fetch logic from database.
            let stocksData = [];

            try {
                setIsLoading(true);
                const { data } = await axios.get(fetchStocksDataRoute,{ withCredentials: true, params: { type: "stocksOnly" }});

                if (data.status === true) {      
                    for (let i = data.stocksData.length - 1; i >= 0; i--) {
                        let productObj = { 
                            id: data.stocksData[i].productId, 
                            code: data.stocksData[i].productCode,
                            name: data.stocksData[i].productName, 
                            sizes: data.stocksData[i].sizes,
                            categories: data.stocksData[i].categories, 
                            stocks: data.stocksData[i].stocks,
                            status: data.stocksData[i].disabled,
                            description: data.stocksData[i].description 
                        }

                        stocksData.push(productObj);
                    }

                    setProducts(stocksData);
                    setIsLoading(false);
                } else {
                    Swal.fire(
                        "Error!",
                        data.msg,
                        "error"
                    );
                }
            } catch (error) {
                // Check if the error response exists (server returned a response)
                if (error.response) {
                    const status = error.response.status;
                    const responseData = error.response.data; // The server's response data
    
                    // Handle different status codes
                    if (status === 403 || status === 401) {
                        await Swal.fire({
                            icon: 'error',
                            title: 'Access Denied!',
                            text: 'Your session may have expired.',
                            timer: 3000,
                            timerProgressBar: true
                        });
    
                        window.localStorage.clear();
                        navigate('/portal/login');
                    } else if (status === 404) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Not Found!',
                            text: 'The requested resource could not be found.',
                        });
                    } else {
                        // Handle other errors or status codes
                        Swal.fire({
                            icon: 'error',
                            title: 'Error!',
                            text: responseData.msg || 'An unexpected error occurred.',
                        });
                    }
                } else if (error.request) {
                    // If no response was received from the server
                    Swal.fire({
                        icon: 'error',
                        title: 'Network Error!',
                        text: 'No response from the server. Please try again later.',
                    });
                } else {
                    // Handle any other errors during the request setup
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Something went wrong. Please try again.',
                    });
                }
            }
        };

        fetchData();
    }, [csrfToken, navigate]);

    useEffect(() => {
        setStockPage(1);
    }, [searchTerm]);

    //Refresh function
    const fetchData = async () => {
        // Fetch logic from database.
        let stocksData = [];

        try {
            setIsLoading(true);
            const { data } = await axios.get(fetchStocksDataRoute, { withCredentials: true, params: { type: "stocksOnly" }});

            if (data.status === true) {      
                for (let i = data.stocksData.length - 1; i >= 0; i--) {
                    let productObj = { 
                        id: data.stocksData[i].productId, 
                        code: data.stocksData[i].productCode,
                        name: data.stocksData[i].productName, 
                        sizes: data.stocksData[i].sizes,
                        categories: data.stocksData[i].categories, 
                        stocks: data.stocksData[i].stocks,
                        status: data.stocksData[i].disabled,
                        description: data.stocksData[i].description 
                    }

                    stocksData.push(productObj);
                }

                setProducts(stocksData);
                setIsLoading(false);
            } else {
                Swal.fire(
                    "Error!",
                    data.msg,
                    "error"
                );
            }
        } catch (error) {
            // Check if the error response exists (server returned a response)
            if (error.response) {
                const status = error.response.status;
                const responseData = error.response.data; // The server's response data

                // Handle different status codes
                if (status === 403 || status === 401) {
                    await Swal.fire({
                        icon: 'error',
                        title: 'Access Denied!',
                        text: 'Your session may have expired.',
                        timer: 3000,
                        timerProgressBar: true
                    });

                    window.localStorage.clear();
                    navigate('/portal/login');
                } else if (status === 404) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Not Found!',
                        text: 'The requested resource could not be found.',
                    });
                } else {
                    // Handle other errors or status codes
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: responseData.msg || 'An unexpected error occurred.',
                    });
                }
            } else if (error.request) {
                // If no response was received from the server
                Swal.fire({
                    icon: 'error',
                    title: 'Network Error!',
                    text: 'No response from the server. Please try again later.',
                });
            } else {
                // Handle any other errors during the request setup
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Something went wrong. Please try again.',
                });
            }
        }
    };

    const filteredProducts = products.filter((product) =>
        product.code?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Paginate filtered products
    const paginatedProducts = filteredProducts.slice((stockPage - 1) * itemsPerPage, stockPage * itemsPerPage);
    const totalStockPages = Math.max(1, Math.ceil(filteredProducts.length / itemsPerPage));

    // Pagination controls
    const goToNextStockPage = () => setStockPage(stockPage + 1);
    const goToPrevStockPage = () => setStockPage(stockPage - 1);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };
    
    const handleAddStockClick = (productCode) => {
        setProductCode(productCode);
        setIsStockFormVisible(true);
    };

    const handleStockChange = (currentSize, value) => {
        let objStocks = { stocks: [{ "30x30": quantity30x30 }, { "40x40": quantity40x40 }, { "60x60": quantity60x60 }] };

        objStocks.stocks.forEach((quantity) => {
            if (Object.keys(quantity).toString() === currentSize) {
                quantity[currentSize] = value;
            }
        });

        setStockQuantities(JSON.stringify(objStocks));
    };

    // loading upon submission
    const setButtonState = (button, isDisabled, backgroundColor = '', textColor = '', showSpinner = false) => {
        const buttonText = button.querySelector('.button-text');
        const spinner = button.querySelector('.spinner');
    
        button.disabled = isDisabled;
        button.style.backgroundColor = backgroundColor;
        button.style.color = textColor;
    
        buttonText.style.display = showSpinner ? 'none' : 'inline-block';
        spinner.style.display = showSpinner ? 'inline-block' : 'none';
    };
    
    const handleStockSubmit = async (event) => {
        event.preventDefault();
        //console.log(`Adding ${stockQuantity} stocks to product code: ${selectedProductCode}`);

        const submitButton = event.target.querySelector('button[type="submit"]');
        setButtonState(submitButton, true, '#FFFF00', '#000000', true);

        const confirmation = await Swal.fire({
            title: "Are you sure?",
            text: `This will add stocks to product ${productCode}.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirm"
          }).then((result) => {
            return result.isConfirmed;
        });

        if (!confirmation) {
            setButtonState(submitButton, false, '', '', false);
            return;
        }

        const _user = JSON.parse(window.localStorage.getItem("user"));
        const currentDate = new Date();
        const date = currentDate.toLocaleString();
        const action = "add";

        try {
            const { data } = await axios.post(updateProductStockRoute, { productCode, action, stockQuantities, selectedSizes, _user, date }, {
                headers: {
                    'X-CSRF-Token': csrfToken, // Include CSRF token in headers
                },
                withCredentials: true, // Ensures cookies are sent and received
            });
    
            if (data.status === true) {      
                await Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Stock Updated Successfully!',
                    showConfirmButton: false,
                    timer: 2500
                });
    
                closeStockForm();
                fetchData();
            } else {
                Swal.fire(
                    "Error!",
                    data.msg,
                    "error"
                );
            }
        } catch (error) {
            // Check if the error response exists (server returned a response)
            if (error.response) {
                const status = error.response.status;
                const responseData = error.response.data; // The server's response data

                // Handle different status codes
                if (status === 403) {
                    await Swal.fire({
                        icon: 'error',
                        title: 'Access Denied!',
                        text: 'Your session may have expired.',
                        timer: 3000,
                        timerProgressBar: true
                    });

                    closeStockForm();
                    window.localStorage.clear();
                    navigate('/portal/login');
                } else if (status === 404) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Not Found!',
                        text: 'The requested resource could not be found.',
                    });
                } else {
                    // Handle other errors or status codes
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: responseData.msg || 'An unexpected error occurred.',
                    });
                }
            } else if (error.request) {
                // If no response was received from the server
                Swal.fire({
                    icon: 'error',
                    title: 'Network Error!',
                    text: 'No response from the server. Please try again later.',
                });
            } else {
                // Handle any other errors during the request setup
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Something went wrong. Please try again.',
                });
            }
        }

        setButtonState(submitButton, false, '', '', false);
    };

    const closeStockForm = () => {
        setIsStockFormVisible(false);
        setProductCode(null);
        setStockQuantities('');
        setQuantity30x30(0);
        setQuantity40x40(0);
        setQuantity60x60(0);
        setSelectedSizes([]);
        setIsDisabled30x30(true);
        setIsDisabled40x40(true);
        setIsDisabled60x60(true);
    };

    // Sample data for demonstration
    // const products = [
    //     { code: '1001', quantity: 10, size: '30x30' },
    //     { code: '1002', quantity: 20, size: '40x40' },
    //     { code: '1003', quantity: 30, size: '60x60' },
    //     { code: '1004', quantity: 40, size: '30x30' },
    //     { code: '1005', quantity: 50, size: '40x40' },
    //     { code: '1006', quantity: 60, size: '60x60' },
    //     { code: '1007', quantity: 80, size: '30x30' },
    //     { code: '1008', quantity: 70, size: '40x40' },
    //     { code: '1009', quantity: 90, size: '60x60' },
    // ];

    const sizes = ['30x30', '40x40', '60x60'];
    const sizeData = sizes.map(size => ({
        label: size,
        value: size
    }));
    
    const [isDisabled30x30, setIsDisabled30x30] = useState(true);
    const [isDisabled40x40, setIsDisabled40x40] = useState(true);
    const [isDisabled60x60, setIsDisabled60x60] = useState(true);
    
    // Handle selection changes
    const handleSelectionChange = (selected) => {
        if (selected.includes('all')) {
            // Enable all inputs and reset values
            setSelectedSizes(sizeData.map(size => size.value));
            setIsDisabled30x30(false);
            setIsDisabled40x40(false);
            setIsDisabled60x60(false);
        } else {
            setSelectedSizes(selected);
    
            // Enable or disable inputs and clear values accordingly
            if (!selected.includes('30x30')) {
                setIsDisabled30x30(true);
                setQuantity30x30(0);  // Clear value
            } else {
                setIsDisabled30x30(false);
            }
    
            if (!selected.includes('40x40')) {
                setIsDisabled40x40(true);
                setQuantity40x40(0);  // Clear value
            } else {
                setIsDisabled40x40(false);
            }
    
            if (!selected.includes('60x60')) {
                setIsDisabled60x60(true);
                setQuantity60x60(0);  // Clear value
            } else {
                setIsDisabled60x60(false);
            }
        }
    };
    

    // const getSizeStocks = (prodCode) => {
    //     products.forEach((product) => {
    //         if (product.code === prodCode) {
    //             return product.stocks;
    //         }
    //     });
    // };

    return (
        <div className="adminStockManagement" style={{ display: enablePage }}>
            <div className="topNavStockPageTitle">
                <h3>Stock Management</h3>
            </div>
            <div className="tablesContainer">
                <div className="stocksTableContainer">
                    <div className='searchConStocks'>
                    <h3>Stocks</h3>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="searchProductCode"
                    />
                    </div>
                    <table className="stockTable">
                        <thead>
                            <tr>
                                <th>Product Code</th>
                                <th colSpan="3">No. of Stocks</th>
                                <th className="descriptionColumn">Description</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <tr>
                                    <td colSpan="6">
                                        <div class="loadingOverlay" id="loadingOverlay">
                                            <div class="loadingAnimationCon">
                                                <div class="spinner"></div>
                                                <div class="lblLoading">Loading...</div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ) : null}
                            {paginatedProducts.length === 0 ? (
                                <tr>
                                    <td colSpan="6">No products available.</td>
                                </tr>
                            ) : (
                                paginatedProducts.map((product) => (
                                    <tr key={product.code}>
                                        <td data-label="Product Code">{product.code}</td>
                                        <td
                                            data-label="30x30"
                                            style={{
                                                color: JSON.parse(product.stocks).stocks[0]["30x30"] <= STOCK_THRESHOLD ? 'red' : 'inherit',
                                            }}
                                        >
                                            30x30: {JSON.parse(product.stocks).stocks[0]["30x30"]}
                                        </td>
                                        <td
                                            data-label="40x40"
                                            style={{
                                                color: JSON.parse(product.stocks).stocks[1]["40x40"] <= STOCK_THRESHOLD ? 'red' : 'inherit',
                                            }}
                                        >
                                            40x40: {JSON.parse(product.stocks).stocks[1]["40x40"]}
                                        </td>
                                        <td
                                            data-label="60x60"
                                            style={{
                                                color: JSON.parse(product.stocks).stocks[2]["60x60"] <= STOCK_THRESHOLD ? 'red' : 'inherit',
                                            }}
                                        >
                                            60x60: {JSON.parse(product.stocks).stocks[2]["60x60"]}
                                        </td>
                                        <td data-label="Description" className="descriptionColumn">{product.description}</td>
                                        <td data-label="Action">
                                            <div className="btnAddStocks">
                                                <button onClick={() => handleAddStockClick(product.code)}>
                                                    Add Stock
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                    <div className="pagination">
                        <button disabled={stockPage === 1} onClick={goToPrevStockPage}>Previous</button>
                        <span>Page {stockPage} of {totalStockPages}</span>
                        <button disabled={stockPage === totalStockPages} onClick={goToNextStockPage}>Next</button>
                    </div>
                </div>
            </div>
            {isStockFormVisible && (
                <div className="popupFormContainer">
                    <div className="popupForm">
                        <h2>Add Stock</h2>
                        <form className="stockPopupForm" onSubmit={handleStockSubmit}>
                            <div className="productIdDisplay">
                                <strong>Product Code:</strong> {productCode}
                            </div>
                            {/* <label>
                                Stock Quantity:
                                <input
                                    type="number"
                                    min={1}
                                    name="stockQuantity"
                                    //value={stockQuantities}
                                    onChange={handleStockInputChange}
                                    required
                                />
                            </label> */}
                            <label>Size:</label>
                            <label>
                                <TagPicker
                                    data={[
                                        { label: 'Select All', value: 'all' },
                                        ...sizeData
                                    ]}
                                    value={selectedSizes}
                                    onChange={handleSelectionChange}
                                    style={{ width: 500 }}
                                    placeholder="Select Sizes"
                                    required
                                    searchable={false}
                                />
                            </label>
                            <div className="size-inputs">
                                <label>
                                    30x30:
                                    <input
                                        type="number"
                                        min={1}
                                        name="size30x30"
                                        value={quantity30x30}
                                        disabled={isDisabled30x30}
                                        style={{
                                            backgroundColor: isDisabled30x30 ? '#cdcdcd' : 'white'
                                        }}
                                        onInput={(e) => {
                                            setQuantity30x30(e.target.value);
                                            handleStockChange("30x30", e.target.value);
                                        }}
                                    />
                                </label>
                                <label>
                                    40x40:
                                    <input
                                        type="number"
                                        min={1}
                                        name="size40x40"
                                        value={quantity40x40}
                                        disabled={isDisabled40x40}
                                        style={{
                                            backgroundColor: isDisabled40x40 ? '#cdcdcd' : 'white'
                                        }}
                                        onInput={(e) => {
                                            setQuantity40x40(e.target.value);
                                            handleStockChange("40x40", e.target.value);
                                        }}
                                    />
                                </label>
                                <label>
                                    60x60:
                                    <input
                                        type="number"
                                        min={1}
                                        name="size60x60"
                                        value={quantity60x60}
                                        disabled={isDisabled60x60}
                                        style={{
                                            backgroundColor: isDisabled60x60 ? '#cdcdcd' : 'white'
                                        }}
                                        onInput={(e) => {
                                            setQuantity60x60(e.target.value);
                                            handleStockChange("60x60", e.target.value);
                                        }}
                                    />
                                </label>
                            </div>
                            <div className="formButtons">
                                <button type="submit">
                                    <span className="button-text">Submit</span>
                                    <span className="spinner" style={{ display: 'none' }}></span>
                                </button>
                                <button type="button" onClick={closeStockForm}>Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

        </div>
    );
};

export default StockManagement;
