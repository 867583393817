import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { fetchStocksDataRoute } from '../utils/APIRoutes';
import Swal from 'sweetalert2';
import '../assets/styles/reports.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

// Register the components with Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TileTransactionReport = () => {
  const [reportType, setReportType] = useState('weekly');
  const [transactions, setTransactions] = useState([]);
  const [quotations, setQuotations] = useState([]);
  const [report, setReport] = useState({});
  const [generated, setGenerated] = useState(false);
  const [filterOption, setFilterOption] = useState('productCode');
  const [activeFilterOption, setActiveFilterOption] = useState('productCode');
  const [activeReportType, setActiveReportType] = useState('weekly');
  const [loading, setLoading] = useState(true);
  const [enablePage, setEnablePage] = useState("none");
  const isLoggedIn = window.localStorage.getItem("user");
  const navigate = useNavigate();
  const [currentReportPage, setReportCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const numFormat = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  const chartRef = useRef(null);
  const [chartImage, setChartImage] = useState(null);

  const [isQuotationModalOpen, setIsQuotationModalOpen] = useState(false);
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);
  const [selectedQuotation, setSelectedQuotation] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  useEffect(() => {
    if (isLoggedIn) {
      setEnablePage("block");
      document.title = "JCJHomeBuilders Portal - Reports";
    } else {
      setEnablePage("none");
      navigate("/portal/login");
      document.title = "JCJHomeBuilders Portal - Log In";
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(fetchStocksDataRoute, { withCredentials: true });
        if (data.status === true) {
          setTransactions(data.transactionsData || []);
          setQuotations(data.quotationsData || []);
        } else {
          Swal.fire("Error!", "Unable to fetch report data.", "error");
        }
      } catch (error) {
        handleRequestError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    async function handleRequestError(error)  {
      if (error.response) {
        const status = error.response.status;
        if (status === 403 || status === 401) {
          await Swal.fire({
            icon: 'error',
            title: 'Access Denied!',
            text: 'Your session may have expired.',
            timer: 3000,
            timerProgressBar: true
          });

          window.localStorage.clear();
          navigate('/portal/login');
        } else {
          Swal.fire("Error!", "An unexpected error occurred.", "error");
        }
      } else {
        Swal.fire("Network Error!", "No response from the server. Please try again later.", "error");
      }
    };
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    setReportCurrentPage(1);
  }, [report]);

  const filterDataByReportType = (data, reportType, dateField) => {
    const now = moment();
    let startDate;
    switch (reportType) {
      case 'weekly':
        startDate = now.clone().subtract(1, 'week');
        break;
      case 'monthly':
        startDate = now.clone().subtract(1, 'month');
        break;
      case 'quarterly':
        startDate = now.clone().subtract(3, 'months');
        break;
      case 'yearly':
        startDate = now.clone().subtract(1, 'year');
        break;
      default:
        startDate = now.clone().subtract(1, 'week');
    }
    return data.filter((item) => moment(item[dateField]).isBetween(startDate, now, 'day', '[]'));
  };

  const generateReport = () => {
    if (transactions.length === 0 && quotations.length === 0) {
      return;
    }
  
    // Filter transactions and quotations by report type
    const filteredTransactions = filterDataByReportType(transactions, reportType, 'transactionDate');
    const filteredQuotations = filterDataByReportType(quotations, reportType, 'quotationDate');
  
    const groupedData = {};

    if (filterOption === 'productCode') {
      // Group by Product Code
      filteredTransactions.forEach(transaction => {
        transaction.items.forEach(item => {
          const { productCode, total } = item;
          if (!groupedData[productCode]) {
            groupedData[productCode] = { totalTransactions: 0, totalQuotations: 0, totalAmount: 0 };
          }
          groupedData[productCode].totalTransactions++;
          groupedData[productCode].totalAmount += parseFloat(total);
        });
      });
  
      filteredQuotations.forEach(quotation => {
        quotation.items.forEach(item => {
          const { productCode } = item;
          if (!groupedData[productCode]) {
            groupedData[productCode] = { totalTransactions: 0, totalQuotations: 0, totalAmount: 0 };
          }
          groupedData[productCode].totalQuotations++;
        });
      });
    } else if (filterOption === 'transaction') {
      // Group by Transaction ID
      filteredTransactions.forEach(transaction => {
        const { transactionId, transactionDate, items } = transaction;
        groupedData[transactionId] = {
          transactionId,
          transactionDate,
          items,
          totalAmount: items.reduce((acc, item) => acc + parseFloat(item.total), 0)
        };
      });
    } else if (filterOption === 'quotation') {
      // Group by Quotation ID
      filteredQuotations.forEach(quotation => {
        const { quotationId, quotationDate, quotationExpire, status, userEmail, items } = quotation;
        groupedData[quotationId] = {
          quotationId,
          quotationDate,
          quotationExpire,
          status,
          userEmail,
          items,
          totalAmount: items.reduce((acc, item) => acc + parseFloat(item.total), 0)
        };
      });
    }

    setReport(groupedData);
    setActiveFilterOption(filterOption);
    setActiveReportType(reportType);
    setGenerated(true); // Mark report as generated
  };

  const generateReportTableHTML = () => {
    // Generate HTML for the entire report state
    const rows = Object.keys(report).map((key) => {
      const rowData = report[key];
      return `
        <tr>
          <td>${key}</td>
          ${
            activeFilterOption === 'productCode'
              ? `<td>${rowData.totalTransactions || 0}</td>
                 <td>${rowData.totalQuotations || 0}</td>`
              : ''
          }
          <td>₱${numFormat.format(rowData.totalAmount || 0)}</td>
        </tr>
      `;
    });

    return `
      <table style="width: 100%; border-collapse: collapse;">
        <thead>
          <tr>
            <th>${activeFilterOption === 'productCode' ? 'Product Code' : activeFilterOption === 'transaction' ? 'Transaction ID' : 'Quotation ID'}</th>
            ${
              activeFilterOption === 'productCode'
                ? `<th>Total Transactions</th><th>Total Quotations</th>`
                : ''
            }
            <th>Total Amount (₱)</th>
          </tr>
        </thead>
        <tbody>
          ${rows.join('')}
        </tbody>
      </table>
    `;
  };

  const handlePrint = () => {
    const reportTableHTML = generateReportTableHTML();

    // Create a hidden iframe
    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = 'none';

    document.body.appendChild(iframe);

    const iframeDoc = iframe.contentWindow || iframe.contentDocument;

    // Write the report content to the iframe
    iframeDoc.document.open();
    iframeDoc.document.write(`
      <html>
        <head>
          <title>Print Report</title>
          <style>
            body { font-family: Arial, sans-serif; margin: 20px; }
            table { width: 100%; border-collapse: collapse; }
            th, td { border: 1px solid #ddd; padding: 8px; }
            th { background-color: #f2f2f2; text-align: left; }
          </style>
        </head>
        <body>
          <header style="display: flex; align-items: center; padding: 10px 20px; border-bottom: 1px solid #ddd;">
            <img src="https://scontent.fmnl17-1.fna.fbcdn.net/v/t1.15752-9/462644352_1482015849154386_5157945176322197732_n.png?_nc_cat=101&ccb=1-7&_nc_sid=9f807c&_nc_eui2=AeG7IekKWLRh9XdTUmpqtlt1yoSlA4FsPEHKhKUDgWw8Qa12JpznCt8FzojKW3poF7hU-YwMg8Wt7ob-LL7yFgUq&_nc_ohc=VnmdkMs4fUUQ7kNvgFdpmfF&_nc_zt=23&_nc_ht=scontent.fmnl17-1.fna&oh=03_Q7cD1QEdva7cNKDuZnw9bitzm9rGCFnA72jwLRtGRQ40V_LiJA&oe=675817FB" alt="Logo" style="height: 50px; margin-left: -20px;">
            <h1 style="margin: 0; flex-grow: 1; text-align: center;">Report</h1>
          </header>
          <div>
            ${chartImage ? `<img src="${chartImage}" alt="Chart" style="width: 100%; height: auto; margin-bottom: 20px;" />` : ''}
          </div>
          ${reportTableHTML}
        </body>
      </html>
    `);
    iframeDoc.document.close();

    // Trigger the print action
    setTimeout(() => {
      iframe.contentWindow.focus();
      iframe.contentWindow.print();

      // Remove the iframe after printing
      document.body.removeChild(iframe);
    }, 100);
  };

  const captureChartImage = () => {
    // Capture the canvas as an image and store it in state
    const canvas = chartRef.current?.canvas;
    if (canvas) {
      const imageURL = canvas.toDataURL('image/png');
      setChartImage(imageURL);
    }
  };

  const handlePrintQuotation = () => {
    // Generate the HTML for the quotation modal content
    const quotationHTML = `
      <html>
        <head>
          <title>Print Quotation Details</title>
          <style>
            body { font-family: Arial, sans-serif; margin: 20px; }
            table { width: 100%; border-collapse: collapse; }
            th, td { border: 1px solid #ddd; padding: 8px; }
            th { background-color: #f2f2f2; text-align: left; }
          </style>
        </head>
        <body>
          <header style="display: flex; align-items: center; padding: 10px 20px; border-bottom: 1px solid #ddd;">
            <img src="https://scontent.fmnl17-1.fna.fbcdn.net/v/t1.15752-9/462644352_1482015849154386_5157945176322197732_n.png?_nc_cat=101&ccb=1-7&_nc_sid=9f807c&_nc_eui2=AeG7IekKWLRh9XdTUmpqtlt1yoSlA4FsPEHKhKUDgWw8Qa12JpznCt8FzojKW3poF7hU-YwMg8Wt7ob-LL7yFgUq&_nc_ohc=VnmdkMs4fUUQ7kNvgFdpmfF&_nc_zt=23&_nc_ht=scontent.fmnl17-1.fna&oh=03_Q7cD1QEdva7cNKDuZnw9bitzm9rGCFnA72jwLRtGRQ40V_LiJA&oe=675817FB" alt="Logo" style="height: 50px; margin-left: -20px;">            
            <h1 style="margin: 0; flex-grow: 1; text-align: center;">Quotation Details</h1>
          </header>

          <h3>Quotation Details for ID: ${selectedQuotation.quotationId}</h3>
          <p><strong>Date:</strong> ${new Date(selectedQuotation.quotationDate).toLocaleString()}</p>
          <p><strong>Expiry Date:</strong> ${new Date(selectedQuotation.quotationExpire).toLocaleString()}</p>
          <p><strong>Status:</strong> ${selectedQuotation.status}</p>
          <p><strong>Billed to:</strong> ${selectedQuotation.userEmail}</p>
  
          <h4>Items</h4>
          <table style="width: 100%; border-collapse: collapse;">
            <thead>
              <tr>
                <th>Product Code</th>
                <th>Size</th>
                <th>Quantity</th>
                <th>Price (₱)</th>
                <th>Tax (₱)</th>
                <th>Total (₱)</th>
              </tr>
            </thead>
            <tbody>
              ${selectedQuotation.items.map(item => `
                <tr>
                  <td>${item.productCode}</td>
                  <td>${item.size}</td>
                  <td>${item.quantity}</td>
                  <td>₱${numFormat.format(parseFloat(item.price))}</td>
                  <td>₱${numFormat.format(parseFloat(item.tax))}</td>
                  <td>₱${numFormat.format(parseFloat(item.total))}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
        </body>
      </html>
    `;

    // Create a hidden iframe
    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = 'none';

    document.body.appendChild(iframe);

    const iframeDoc = iframe.contentWindow || iframe.contentDocument;

    // Write the quotation HTML to the iframe
    iframeDoc.document.open();
    iframeDoc.document.write(quotationHTML);
    iframeDoc.document.close();

    // Trigger the print action
    setTimeout(() => {
      iframe.contentWindow.focus();
      iframe.contentWindow.print();

      // Remove the iframe after printing
      document.body.removeChild(iframe);
    }, 100);
  };

  const handlePrintTransaction = () => {
    // Generate the HTML for the transaction modal content
    const transactionHTML = `
      <html>
        <head>
          <title>Print Transaction Details</title>
          <style>
            body { font-family: Arial, sans-serif; margin: 20px; }
            table { width: 100%; border-collapse: collapse; }
            th, td { border: 1px solid #ddd; padding: 8px; }
            th { background-color: #f2f2f2; text-align: left; }
          </style>
        </head>
        <body>
          <header style="display: flex; align-items: center; padding: 10px 20px; border-bottom: 1px solid #ddd;">
            <img src="https://scontent.fmnl17-1.fna.fbcdn.net/v/t1.15752-9/462644352_1482015849154386_5157945176322197732_n.png?_nc_cat=101&ccb=1-7&_nc_sid=9f807c&_nc_eui2=AeG7IekKWLRh9XdTUmpqtlt1yoSlA4FsPEHKhKUDgWw8Qa12JpznCt8FzojKW3poF7hU-YwMg8Wt7ob-LL7yFgUq&_nc_ohc=VnmdkMs4fUUQ7kNvgFdpmfF&_nc_zt=23&_nc_ht=scontent.fmnl17-1.fna&oh=03_Q7cD1QEdva7cNKDuZnw9bitzm9rGCFnA72jwLRtGRQ40V_LiJA&oe=675817FB" alt="Logo" style="height: 50px; margin-left: -20px;">            
            <h1 style="margin: 0; flex-grow: 1; text-align: center;">Transaction Details</h1>
          </header>

          <p><strong>Transaction ID:</strong> ${selectedTransaction.transactionId}</p>
          <p><strong>Date:</strong> ${new Date(selectedTransaction.transactionDate).toLocaleString()}</p>
          <p><strong>Total Amount:</strong> ₱${numFormat.format(parseFloat(selectedTransaction.totalAmount))}</p>
  
          <h4>Items</h4>
          <table style="width: 100%; border-collapse: collapse;">
            <thead>
              <tr>
                <th>Product Code</th>
                <th>Product Name</th>
                <th>Size</th>
                <th>Quantity</th>
                <th>Price (₱)</th>
                <th>Tax (₱)</th>
                <th>Total (₱)</th>
              </tr>
            </thead>
            <tbody>
              ${selectedTransaction.items.map(item => `
                <tr>
                  <td>${item.productCode}</td>
                  <td>${item.productName}</td>
                  <td>${item.size}</td>
                  <td>${item.quantity}</td>
                  <td>₱${numFormat.format(parseFloat(item.price))}</td>
                  <td>₱${numFormat.format(parseFloat(item.tax))}</td>
                  <td>₱${numFormat.format(parseFloat(item.total))}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
        </body>
      </html>
    `;

    // Create a hidden iframe
    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = 'none';

    document.body.appendChild(iframe);

    const iframeDoc = iframe.contentWindow || iframe.contentDocument;

    // Write the transaction HTML to the iframe
    iframeDoc.document.open();
    iframeDoc.document.write(transactionHTML);
    iframeDoc.document.close();

    // Trigger the print action
    setTimeout(() => {
      iframe.contentWindow.focus();
      iframe.contentWindow.print();

      // Remove the iframe after printing
      document.body.removeChild(iframe);
    }, 100);
  };

  const handleReportTypeChange = (e) => {
    setReportType(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilterOption(e.target.value);
    if (generated) {
      generateReport();
    }
  };

  // Handle opening quotation modal
  const handleViewQuotationDetails = (quotation) => {
    setSelectedQuotation(quotation);
    setIsQuotationModalOpen(true);
  };

  // Handle opening transaction modal
  const handleViewTransactionDetails = (transaction) => {
    setSelectedTransaction(transaction);
    setIsTransactionModalOpen(true);
  };

  // Handle closing quotation modal
  const closeQuotationModal = () => {
    setIsQuotationModalOpen(false);
    setSelectedQuotation(null);
  };

  // Handle closing transaction modal
  const closeTransactionModal = () => {
    setIsTransactionModalOpen(false);
    setSelectedTransaction(null);
  };

  const handleGenerateReport = () => {
    generateReport();
  };

  const totalPages = Math.max(1, Math.ceil(Object.keys(report).length / itemsPerPage));
  const paginatedReport = Object.keys(report)
    .slice((currentReportPage - 1) * itemsPerPage, currentReportPage * itemsPerPage)
    .reduce((acc, key) => {
      acc[key] = report[key];
      return acc;
    }, {});

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setReportCurrentPage(newPage);
    }
  };

  // Prepare data for the line chart
  /*const chartData = {
    labels: Object.keys(report), // Each product code as a label
    datasets: [
      {
        label: 'Total Amount (₱)',
        data: Object.values(report).map((data) => data.totalAmount),
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.4)',
        tension: 0.2
      }
    ]
  };*/

  // Assuming 'report' is an object where each entry contains a date field (e.g., 'transactionDate' or 'quotationDate')
  const chartData = {
    // Use the date field from each report entry as the labels for the chart
    labels: activeFilterOption === "productCode" 
    ? Object.keys(report) 
    : Object.values(report)
      .map((data) => {
        // Extract the date field based on filter option
        if (activeFilterOption === 'transaction') {
          const date = new Date(data.transactionDate);
          const label = activeReportType === 'yearly' 
            ? date.toLocaleString('default', { month: 'long' }) // Full month name
            : date.toLocaleDateString(); // Default format for other types
          return { date, label };
        } else if (activeFilterOption === 'quotation') {
          const date = new Date(data.quotationDate);
          const label = activeReportType === 'yearly' 
            ? date.toLocaleString('default', { month: 'long' }) // Full month name
            : date.toLocaleDateString(); // Default format for other types
          return { date, label };
        }
      })
      // Sort the data by date in ascending order
      .sort((a, b) => a.date - b.date) 
      // Extract the labels in the sorted order
      .map((item) => item.label),

    datasets: [
      {
        label: 'Total Amount (₱)',
        data: Object.values(report).map((data) => data.totalAmount),
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.4)',
        tension: 0.2
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true
      }
    },
    animation: {
      onComplete: captureChartImage, // Capture image once chart is fully rendered
    },
  };
  
  return (
    <div className="adminReport" style={{ display: enablePage }}>
      <div className="topNavReports">
        <h3>Tile Transactions & Quotations Report</h3>
      </div>

      <div className='searchContainerLogs'>
          <div className='generateCon'>
            <label className="reportLabel" htmlFor="reportType">Choose Report Type:</label>
            <select className="selectReportType" id="reportType" value={reportType} onChange={handleReportTypeChange}>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="quarterly">Quarterly</option>
              <option value="yearly">Annually</option>
            </select>
            <select className="filter" id="filter" value={filterOption} onChange={handleFilterChange}>
              <option value="transaction">By Transaction</option>
              <option value="quotation">By Quotation</option>
              <option value="productCode">By Product Code</option>
            </select>
            <button 
              className={`generateReportButton ${loading ? 'loading' : ''}`} 
              onClick={handleGenerateReport} 
              disabled={loading}
            >
              {loading ? <span className="spinner"></span> : "Generate Report"}
            </button>
          </div>

          <div className='printCon'>
            <button className='btnPrint' onClick={handlePrint}><p></p>Print <i className="bi bi-printer-fill"></i></button>
          </div>
        </div>


      <div className="tableContainer">
        <div className='mainContentReport'>
          <div className="reportContentContainer">
            <div className="lineChartContainer">
              <Line className="lineChart" ref={chartRef} data={chartData} options={chartOptions} />
            </div>
            <div className='conTableReport'>
              {Object.keys(report).length > 0 ? (
                <table className="reportTable">
                  <thead>
                    <tr>
                      {activeFilterOption === 'productCode' && <th>Product Code</th>}
                      {activeFilterOption === 'transaction' && <th>Transaction ID</th>}
                      {activeFilterOption === 'quotation' && <th>Quotation ID</th>}
                      {activeFilterOption === 'productCode' && <th>Total Transactions</th>}
                      {activeFilterOption === 'productCode' && <th>Total Quotations</th>}
                      <th>Total Amount (₱)</th>
                      {activeFilterOption !== 'productCode' && <th>Actions</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(paginatedReport).map((key) => (
                      <tr key={key}>
                        <td>{key}</td>
                        {activeFilterOption === 'productCode' && (
                          <>
                            <td>{report[key].totalTransactions}</td>
                            <td>{report[key].totalQuotations}</td>
                          </>
                        )}
                        <td>₱{numFormat.format(report[key].totalAmount || 0)}</td>
                        {activeFilterOption !== 'productCode' && (
                          <td>
                            {activeFilterOption === 'transaction' && (
                              <button className='btnViewReport' onClick={() => handleViewTransactionDetails(report[key])}>
                                View Details
                              </button>
                            )}
                            {activeFilterOption === 'quotation' && (
                              <button className='btnViewReport' onClick={() => handleViewQuotationDetails(report[key])}>
                                View Details
                              </button>
                            )}
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>{generated ? `No ${reportType} data to report.` : "No report generated yet."}</p>
              )}
            </div>
          </div>
        </div>

        <div className="pagination">
        <button onClick={() => handlePageChange(currentReportPage - 1)} disabled={currentReportPage === 1}>
          Previous
        </button>
        <span>Page {currentReportPage} of {totalPages}</span>
        <button onClick={() => handlePageChange(currentReportPage + 1)} disabled={currentReportPage === totalPages}>
          Next
        </button>
      </div>
      <br /><br />
      </div>
       


      {isQuotationModalOpen && selectedQuotation && (
        <div className="modalOverlay">
          <div className="manualDecreaseModalContent">
            <button className="closeButton" onClick={closeQuotationModal}>✖</button>
            <h3>Quotation Details for ID: {selectedQuotation.quotationId}</h3>
            <p><strong>Date:</strong> {new Date(selectedQuotation.quotationDate).toLocaleString()}</p>
            <p><strong>Expiry Date:</strong> {new Date(selectedQuotation.quotationExpire).toLocaleString()}</p>
            <p><strong>Status:</strong> {selectedQuotation.status}</p>
            <p><strong>Billed to:</strong> {selectedQuotation.userEmail}</p>
            
            <h4>Items</h4>
            <div className="tableWrapper">
              <table className="detailsTable">
                <thead>
                  <tr>
                    <th>Product Code</th>
                    <th>Size</th>
                    <th>Quantity</th>
                    <th>Price (₱)</th>
                    <th>Tax (₱)</th>
                    <th>Total (₱)</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedQuotation.items.map((item, index) => (
                    <tr key={index}>
                      <td>{item.productCode}</td>
                      <td>{item.size}</td>
                      <td>{item.quantity}</td>
                      <td>₱{numFormat.format(parseFloat(item.price))}</td>
                      <td>₱{numFormat.format(parseFloat(item.tax))}</td>
                      <td>₱{numFormat.format(parseFloat(item.total))}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button className='btnPrintTransaction' onClick={handlePrintQuotation}><p></p>Print <i className="bi bi-printer-fill"></i></button>
            </div>
          </div>
        </div>
      )}

      {isTransactionModalOpen && selectedTransaction && (
        <div className="modalOverlay">
          <div className="manualDecreaseModalContent">
            <button className="closeButton" onClick={closeTransactionModal}>✖</button>
            <h3>Transaction Details</h3>
            <p><strong>Transaction ID:</strong> {selectedTransaction.transactionId}</p>
            <p><strong>Date:</strong> {new Date(selectedTransaction.transactionDate).toLocaleString()}</p>
            <p><strong>Total Amount:</strong> ₱{numFormat.format(parseFloat(selectedTransaction.totalAmount))}</p>

            <div className="tableWrapper">
              <table className="detailsTable">
                <thead>
                  <tr>
                    <th>Product Code</th>
                    <th>Product Name</th>
                    <th>Size</th>
                    <th>Quantity</th>
                    <th>Price (₱)</th>
                    <th>Tax (₱)</th>
                    <th>Total (₱)</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedTransaction.items.map((item, index) => (
                    <tr key={index}>
                      <td>{item.productCode}</td>
                      <td>{item.productName}</td>
                      <td>{item.size}</td>
                      <td>{item.quantity}</td>
                      <td>₱{numFormat.format(parseFloat(item.price))}</td>
                      <td>₱{numFormat.format(parseFloat(item.tax))}</td>
                      <td>₱{numFormat.format(parseFloat(item.total))}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button className='btnPrintTransaction' onClick={handlePrintTransaction}><p></p>Print <i className="bi bi-printer-fill"></i></button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TileTransactionReport;