import React, { useState, useEffect } from 'react';
import '../assets/styles/logPage.css';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2';
import { fetchLogsDataRoute } from '../utils/APIRoutes';

const LogPage = () => {
    const [searchLogTerm, setSearchLogTerm] = useState('');
    const [logsData, setLogData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const isLoggedIn = window.localStorage.getItem("user");
    const navigate = useNavigate();
    const [enablePage, setEnablePage] = useState("none");
    const [logPage, setLogPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(8);

    useEffect(() => {
        if (isLoggedIn) {
            //navigate("/portal/logs-page");
            document.title = "JCJHomeBuilders Portal - Logs Page";
            setEnablePage("block");
        } else {
            setEnablePage("none");
            navigate("/portal/login");
            document.title = "JCJHomeBuilders Portal - Log In";
        } 
    }, [isLoggedIn, navigate]);

    useEffect(() => {
        const fetchData = async () => {
            // Fetch logic from database.
            let logsTempData = [];
            setIsLoading(true);

            try {
                const { data } = await axios.get(fetchLogsDataRoute, { withCredentials: true });

                if (data.status === true) {      
                    for (let i = data.logsData.length - 1; i >= 0; i--) {
                        let logObj = { 
                            item: data.logsData[i].item, 
                            date: data.logsData[i].date,
                            user: data.logsData[i].user, 
                            action: data.logsData[i].action
                        }

                        logsTempData.push(logObj);
                    }
                    
                    setLogData(logsTempData);
                    setIsLoading(false);
                } else {
                    Swal.fire(
                        "Error!",
                        data.msg,
                        "error"
                    );
                }
            } catch (error) {
                // Check if the error response exists (server returned a response)
                if (error.response) {
                    const status = error.response.status;
                    const responseData = error.response.data; // The server's response data

                    // Handle different status codes
                    if (status === 403 || status === 401) {
                        await Swal.fire({
                            icon: 'error',
                            title: 'Access Denied!',
                            text: 'Your session may have expired.',
                            timer: 3000,
                            timerProgressBar: true
                        });

                        window.localStorage.clear();
                        navigate('/portal/login');
                    } else if (status === 404) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Not Found!',
                            text: responseData.msg || 'The requested resource could not be found.',
                        });
                    } else {
                        // Handle other errors or status codes
                        Swal.fire({
                            icon: 'error',
                            title: 'Error!',
                            text: responseData.msg || 'An unexpected error occurred.',
                        });
                    }
                } else if (error.request) {
                    // If no response was received from the server
                    Swal.fire({
                        icon: 'error',
                        title: 'Network Error!',
                        text: 'No response from the server. Please try again later.',
                    });
                } else {
                    // Handle any other errors during the request setup
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Something went wrong. Please try again.',
                    });
                }
            }
        };

        fetchData();
    }, [navigate]);

    useEffect(() => {
        // Calculate the average length of the action column's text
        const totalActionLength = logsData.reduce((total, log) => total + log.action.length, 0);
        const avgActionLength = totalActionLength / (logsData.length || 1);

        // Adjust items per page: longer actions lead to fewer items per page, shorter to more
        let adjustedItemsPerPage;
        if (avgActionLength < 60) {
            adjustedItemsPerPage = 8; // Max items per page for shorter action text
        } else if (avgActionLength < 100) {
            adjustedItemsPerPage = 4; // Medium items per page for moderate action text
        } else {
            adjustedItemsPerPage = 2; // Fewer items for longer action text
        }

        setItemsPerPage(adjustedItemsPerPage);
    }, [logsData]);

    useEffect(() => {
        setLogPage(1);
    }, [searchLogTerm]);

    // Handle search input change
    const handleLogSearchChange = (event) => {
        setSearchLogTerm(event.target.value);
    };

    // Filter logs based on search term
    const filteredLogs = logsData.filter((log) =>
        log.item.toLowerCase().includes(searchLogTerm.toLowerCase())
    );

    // Paginate the filtered logs
    const paginatedLogs = filteredLogs.slice(
        (logPage - 1) * itemsPerPage,
        logPage * itemsPerPage
    );

    // Calculate total pages based on filtered logs
    const totalLogPages = Math.max(1, Math.ceil(filteredLogs.length / itemsPerPage));

    // Pagination controls
    const goToNextLogPage = () => setLogPage(logPage + 1);
    const goToPrevLogPage = () => setLogPage(logPage - 1);

    return (
        <div className="adminLogPage" style={{ display: enablePage }}>
            <div className="topNavLogPageTitle">
                <h3>Logs Page</h3>
            </div>
            <div className="tableContainerLog">
                <div className="searchContainerLogPage">
                    <input
                        type="text"
                        placeholder="Search by Item"
                        value={searchLogTerm}
                        className="txtSearchLog"
                        onChange={handleLogSearchChange}
                    />
                </div>

                {isLoading ? (
                    <div class="loadingOverlay" id="loadingOverlay">
                        <div class="loadingAnimationCon">
                            <div class="spinner"></div>
                            <div class="lblLoading">Loading...</div>
                        </div>
                    </div>

                ) : (
                    <table className="logTable">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Date</th>
                                <th>User</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedLogs.length === 0 ? (
                                <tr>
                                    <td colSpan="4">No logs available.</td>
                                </tr>
                            ) : (
                                paginatedLogs.map((log, index) => (
                                    <tr key={index}>
                                        <td>{log.item}</td>
                                        <td>{new Date(log.date).toLocaleString()}</td>
                                        <td>{log.user}</td>
                                        <td>{log.action}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                )}
            </div>
            <div className="pagination">
                <button disabled={logPage === 1} onClick={goToPrevLogPage}>
                    Previous
                </button>
                <span>Page {logPage} of {totalLogPages}</span>
                <button disabled={logPage === totalLogPages} onClick={goToNextLogPage}>
                    Next
                </button>
            </div>
        </div>
    );
};

export default LogPage;
